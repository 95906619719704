import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AppService} from "../../shared/services/app.service";
import {ThemeService} from "../../shared/services/core/theme.service";
import {LayoutService} from "../../shared/services/core/layout.service";
import {HttpClient} from "@angular/common/http";
import {ClientSite, User} from "../../shared/models/models";
import {egretAnimations} from "../../shared/animations/egret-animations";
import {SiteService} from "../../shared/services/site.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {environment} from '../../../environments/environment';
import {H} from "../../shared/helpers/H";

// https://maps.googleapis.com/maps/api/js?key=AIzaSyCiv74FkdNizesgiVNHSrPZUkN90LKZUD8 <--- google map api
@Component({
    selector: 'app-site-home',
    templateUrl: './site-home.component.html',
    styleUrls: ['./site-home.component.scss'],
    animations: egretAnimations
})
export class SiteHomeComponent implements OnInit {
    @ViewChild('listHolder') listHolder: ElementRef;
    //  DASHBOARD: ['BILAN_CONSO', 'BILAN_LAST', 'ACTIONS_SUMMARY'],
    mode = 'std'; // editSite, editRoles

    storedSites = [];
    bddSites = [];
    linkingPreview = "";
    selectedUser: User;
    clientSiteLoaded = false;

    get userGroups(): string[] {
        let retVal = []
        if (this.site && this.site.responsibles && Array.isArray(this.site.responsibles)) {
            this.site.responsibles.forEach(it => {
                retVal.push(it.group);
            });
            retVal = H.arrayUnique(retVal).sort();

        }
        return retVal;
    }

    constructor(
        public rdb: AngularFireDatabase,
        public myapp: AppService,
        public site: SiteService,
        private themeService: ThemeService,
        private layout: LayoutService,
    ) {
        this.myapp.isDisplayedComponentAdmin = false;
    }

    ngOnInit(): void {
        this.layout.publishLayoutChange({sidebarStyle: 'closed'});

        if (this.myapp.user)
            this.myapp.storeCurrentRoute();
        else
            this.myapp.appInitStatus.subscribe(status => {
                if (status === 1)
                    this.myapp.storeCurrentRoute();
            });

        this.clientSiteLoaded = false;
        this.site.siteLoadingStatus.subscribe(eventName => {
            if (eventName === SiteService.MAX_SITE_LOADING_STATUS) {
                this.clientSiteLoaded = true;
            }
        });
    }

    manageFav(act: string) {
        let favs = this.myapp.user.favs;
        if (act === 'add') favs.push(this.site.uid);
        else favs = this.myapp.user.favs.filter(f => f !== this.site.uid);
        console.log("favManagement", act, this.myapp.user.uid, favs);
        this.myapp.db.collection('users')
            .doc<any>(this.myapp.user.uid)
            .set({favs: favs}, {merge: true})
            .then(user => {

            });
    }

    sortBddList(key) {
        const numberedKeys = ['ligne', 'uid_batiment', 'uid_secteur'];
        this.bddSites = this.bddSites.sort((a, b) => {
            if (numberedKeys.includes(key))
                return a[key] - b[key];
            else
                return a[key].localeCompare(b[key]);
        });
    }

    drop($ev, evname) {
        if (evname === "sorted") {
            const parent = this.listHolder.nativeElement as HTMLDivElement;
            const childrenListItems = parent.getElementsByClassName('drop');
            const currentSelected = parent.getElementsByClassName('drop-' + $ev.currentIndex).item(0) as HTMLDivElement;
            for (let i = 0; i < childrenListItems.length; i++) childrenListItems.item(i).classList.remove('selected');
            currentSelected.classList.add("selected");
        }
        this.linkingPreview = $ev.item.data.bdd + " " + $ev.item.data.batiment
            + " ==> Sera sssocié à: [" + this.storedSites[$ev.currentIndex].ref + "] ";
        console.log("Aterrissage:" + evname, $ev.item.data, this.storedSites[$ev.currentIndex]);
    }
}
