import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {H} from "../../../shared/helpers/H";
import moment from "moment";
import {PlotlyComponent, PlotlyService} from "angular-plotly.js";
import {
    barDataItem,
    progressGaugeLayout,
    pieData,
    pieLayout,
    colorsMap,
    bmensBars,
    bmensProgressData
} from "./plots";
import {interval} from "rxjs";
import Plotly from 'plotly.js-dist-min'
import {SiteEvent} from "../../../shared/models/Notifs";
import {BmensData, BmensMeter} from "../../../shared/models/Bmens.model";
import {plotEfficiencyData} from "../../reports/suivi/plots-config";
import {User} from "../../../shared/models/models";

@Component({
    selector: 'monitor-bmens',
    template: `

        <div fxLayout="column" class="full-width h100 h-100">
            <!-- left listfxLayoutAlign="space-between start" -->
            <mat-card class="default full-width m-4 p-0">
                <div fxLayout="row">
                    <button mat-raised-button class="btn-xs" [class.active]="selectedYear===y"
                            *ngFor="let y of years" (click)="selectYear(y)">
                        {{ y }}
                    </button>
                </div>
            </mat-card>

            <div fxLayout="row" class="h-100">
                <div fxLayout="column" fxFlex="800px" fxLayoutAlign="space-between none">
                    <mat-card class="p-0 m-4">
                        <plotly-plot #plotly1
                                     (plotlyClick)="plotClic($event )"
                                     [config]="myapp.k.defaultConfig"
                                     [data]="bmensBars.data"
                                     [layout]="bmensBars.layout">
                        </plotly-plot>
                    </mat-card>
                    <mat-card class="m-0 mb-8 p-4" fxFlex fxLayout="column">
                        <h3>Progression</h3>
                        <div fxFlex fxLayout="row">
                            <div fxFlex="50">
                                <h2>Nb de cmpt. saisis {{ totalMetersDoneCount }}/{{ totalMetersCount }}</h2>
                                <div id="gaugeProgress-meters"></div>
                                <h2>Sites concernés: {{ concerned_sites.length }}</h2>
                                <div id="gaugeProgress-meters-concerned-sites"></div>
                            </div>
                            <div fxFlex="50">
                                <h2>Nb de bmens partagés</h2>
                                <div id="gaugeProgress-shared"></div>
                            </div>
                        </div>
                    </mat-card>
                    <mat-card class="m-0 mb-8 p-4" fxLayout="column">
                        <h3>Répartition par collaborateur</h3>
                        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                            <div fxFlex="550px" id="pie-users"></div>
                        </div>
                    </mat-card>
                </div>

                <!-- list sites -->
                <div fxLayout="column" fxFlex="1100px" class="p-0 m-4">
                    <mat-card class="p-4 m-0 plot-comm-holder">
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="report-item report-item-head ">
                            <div fxFlex="30px" class="weight-bold">
                                N:
                            </div>
                            <div fxFlex="190px" class="font-weight-bold color-blue">
                                Bâtiment
                            </div>
                            <div fxFlex fxLayout="row" class="holder" fxLayoutAlign="start none">
                                Liste des compteurs et la saisie si effectuée pour [{{ myapp.k.months[selectedMonth - 1] }} {{ selectedYear }}]
                            </div>

                        </div>
                        <div class="list-scroller-bmens">
                            <div *ngFor="let sm of sitesWithMeters;let i=index;"
                                 [class.for_user]="highlighedSites.has(sm.siteUUID)"
                                 [class.hide_for_user]="highlighedSites.size>0&&!highlighedSites.has(sm.siteUUID)"
                                 fxLayout="row" fxLayoutAlign="space-between center" class="report-item">

                                <div fxFlex="30px" class="weight-bold">
                                    {{ i + 1 }}
                                </div>
                                <div fxFlex="190px" class="">
                                    {{ sm.siteRef }} ({{ sm.meters.length }})
                                </div>
                                <div fxFlex="750px" fxLayout="row" class="bmens-holder" fxLayoutAlign="space-evenly  none">
                                    <div *ngFor="let m of sm.meters" class="releve-holder"
                                         (click)="selectReleve(sm,m)"
                                         [title]="m.group_label+' | '+m.label+' ('+m.disp_order+')'"
                                         [ngClass]="metersDataMap.has(m.uid_site+m.uid)?'present':'not-present'"
                                         [style.width.px]="750/sm.meters.length-2">
                                        <mat-icon inline *ngIf="metersDataMap.has(m.uid_site+m.uid)">check</mat-icon>
                                    </div>
                                </div>
                                <div fxFlex>
                                    <div class="shared-holder">
                                        <mat-icon *ngIf="eventsMap.has(sm.siteUUID+selectedKey)" style="font-size: 12px" inline>share</mat-icon>
                                        <span *ngIf="eventsMap.has(sm.siteUUID+selectedKey)">Partagé</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </mat-card>
                </div>

                <!-- DETAILS-->
                <div fxFlex>
                    <mat-card class="p-8 m-4 details-card" fxFlex *ngIf="selectedSiteWithMeters||highlighedSitesNames.size">
                        <div *ngIf="highlighedSitesNames.size">
                            <h3>Cet utilisateur a des drtoits BMENS@SHARE sur les sites suivants:</h3>
                            <div fxLayout="row" *ngFor="let kv of highlighedSitesNames|keyvalue;let i=index">
                                <b fxFlex>{{ i + 1 }}: Site: {{ kv.key }}:</b> <span>{{ kv.value }}</span>
                            </div>
                        </div>
                        <ng-container *ngIf="selectedSiteWithMeters">
                            <h3 fxLayout="row">
                                <span fxFlex>{{ selectedSiteWithMeters.siteRef }}</span>
                                <mat-icon (click)="selectedSiteWithMeters=null">close</mat-icon>
                            </h3>
                            <div *ngIf="selectedMeter">
                                <b>Compteur: {{ selectedMeter.label }} {{ selectedMeter.fluid }}</b>
                            </div>
                            <div *ngIf="selectedReleveItem">
                                <div><b>Relevé de:</b> {{ selectedReleveItem.releve_date_short }}</div>
                                <div><b>Date de la saisie:</b> {{ selectedReleveItem.dateCreateStr }}</div>
                            </div>
                            <div *ngIf="!selectedReleveItem">
                                <h2 class="color-red">Non Saisi</h2>
                            </div>
                            <div *ngIf="!selectedShareItem">
                                <h2 class="color-red">Non partagé</h2>
                            </div>
                            <div *ngIf="selectedShareItem">
                                <div><b>Partagé par:</b> {{ selectedShareItem.user_metas.email }}</div>
                                <div><b>Date du partage: </b>{{ selectedShareItem.date_str }}</div>
                                <div><b>Partagé à: </b>
                                    <div *ngFor="let target of selectedShareItem.target">- {{ target }}</div>
                                </div>
                            </div>
                        </ng-container>

                    </mat-card>
                </div>

            </div>
        </div>
    `,
    styleUrls: ['./manager.component.scss']
})
export class MonitorBmensComponent implements OnInit, OnDestroy {
    @ViewChild('plotly1', {static: true}) plotly1: PlotlyComponent;
    @ViewChild('plotProgress', {static: true}) plotProgress: PlotlyComponent;

    protected readonly progressData = bmensProgressData;
    protected readonly progressGaugeLayout = progressGaugeLayout as Plotly.Layout;
    protected readonly pieData = pieData;
    protected readonly pieLayout = pieLayout;
    protected readonly colorsMap = colorsMap;
    protected readonly bmensBars = bmensBars;
    protected readonly barDataItem = barDataItem;


    events: SiteEvent[] = [];
    meters: BmensMeter[] = [];
    metersMap: Map<string, BmensMeter> = new Map<string, BmensMeter>();
    metersData: BmensData[] = [];
    metersDataMap: Map<string, BmensData> = new Map<string, BmensData>();//key = uid_site+meter_uid
    eventsMap: Map<string, SiteEvent> = new Map<string, SiteEvent>();//key = uid_site+key
    sitesWithMeters: SiteWithMeters[] = [];

    years: number[] = [];
    selectedYear = 0;
    selectedMonth = 0;
    selectedKey = "";
    selectedMeter: BmensMeter = null;
    selectedSiteWithMeters: SiteWithMeters = null;
    selectedReleveItem: BmensData = null;
    selectedShareItem: SiteEvent = null;
    highlighedSites: Map<string, string> = new Map<string, string>();
    highlighedSitesNames: Map<string, string> = new Map<string, string>();
    // data analysis
    perHour: Map<string, number> = new Map<string, number>();
    perDay: Map<string, number> = new Map<string, number>();

    perUser: Map<string, number> = new Map<string, number>();
    perUserLabel: Map<string, string> = new Map<string, string>();
    userLabelToEmailMap: Map<string, string> = new Map<string, string>();

    totalMetersDoneCount = 0;
    totalMetersCount = 0;
    concerned_sites = [];
    renderedDivIds = {};//to decide if to animate or newPlot

    constructor(
        public myapp: AppService,
        public site: SiteService,
        public plotlyServ: PlotlyService
    ) {

    }

    ngOnInit(): void {
        this.selectedYear = moment().year();
        this.selectedMonth = moment().week();

        this.years.push(2023);
        this.years.push(2024);
        this.years.push(2025);
        this.site.siteLoadingStatus.subscribe(eventName => {
            if (eventName === SiteService.MAX_SITE_LOADING_STATUS) {
                if (this.myapp.user) {
                    this.preRun();
                } else {
                    this.myapp.appInitStatus.subscribe(step => {
                        if (step === 1)
                            this.preRun();
                    });
                }
            }
        });
    }

    preRun() {
        if (this.myapp.user.isAdminOrSuperUser)
            this.run();
        else {
            this.myapp.showError("Accès reservé aux administrateurs");
            setTimeout(() => {
                window.history.back();
            }, 4000);
        }
    }

    run() {
        this.myapp.storeCurrentRoute();
        this.getData();
    }

    selectYear(y: number) {
        this.perDay = new Map<string, number>();
        this.selectedYear = y;
        this.selectedMonth = 0;
        this.getData();
    }

    getData() {
        this.sitesWithMeters = [];
        this.myapp.loader.open('Chargement...');
        this.myapp.api.getBmensEvents(this.selectedYear).subscribe(resp => {
            // console.log("this.events", resp);
            this.totalMetersCount = 0;
            this.myapp.loader.close();
            this.metersMap = new Map<string, BmensMeter>();
            this.events = resp.events.map(it => {
                const e = new SiteEvent(it);
                const k = e.related_data['site_id'] + e.related_data['report_key'];
                // console.log("events", e, k, e.related_data);
                this.eventsMap.set(k, e);
                return e;
            });
            Object.keys(resp.metersBySite).forEach(siteRef => {
                const meters = resp.metersBySite[siteRef];
                const siteWithMeter = new SiteWithMeters(siteRef, meters);
                // console.log("Inner", siteRef, siteWithMeter);
                siteWithMeter.meters.forEach(m => {
                    this.meters.push(m);
                    this.metersMap.set(m.uid, m);
                    this.totalMetersCount++;
                });
                this.sitesWithMeters.push(siteWithMeter);
            });
            this.analyzeData();
        });
    }

    analyzeData() {
        this.perDay = new Map<string, number>();
        const reportShares = JSON.parse(JSON.stringify(this.barDataItem));
        reportShares.name = "";
        reportShares.marker.color = '#5555ff';
        this.bmensBars.data = [reportShares];

        this.bmensBars.layout.title.text = "Nombre de rapports partagés";
        this.bmensBars.layout.xaxis.title = "Mois";
        this.bmensBars.layout.yaxis.title = "Nb de rapports";
        this.bmensBars.layout.datarevision++;

        this.events
            .sort((a, b) => a.ts - b.ts)
            .forEach(it => {
                const key = it.related_data['report_key'];
                this.perDay = H.incrStatMap(this.perDay, key);
            });

        console.log("this.perDay", this.perDay)

        this.renderBars();
        // this.applyFilters();
    }

    renderBars() {
        this.bmensBars.data[0].x = [];
        this.bmensBars.data[0].y = [];
        this.bmensBars.data[0].marker.color = [];
        this.bmensBars.data[0].values = [];
        this.bmensBars.data[0].labels = [];
        for (let i = 1; i <= 12; i++) {
            if (i === this.selectedMonth)
                this.bmensBars.data[0].marker.color.push('#ff7f0e');
            else
                this.bmensBars.data[0].marker.color.push('#6666FF');
            // console.log("RENDER_BARS", i, this.selectedMonth);
            this.bmensBars.data[0].x.push(i);
            const key = this.selectedYear + (i < 10 ? 0 + i.toString() : i.toString());
            const v = this.perDay.has(key) ? this.perDay.get(key) : 0;
            this.bmensBars.data[0].y.push(v);
        }
        this.bmensBars.layout.datarevision++;
    }

    renderGauges() {
        this.totalMetersDoneCount = 0;
        this.concerned_sites = [];
        const gaugeDataMeters = JSON.parse(JSON.stringify(this.progressData));
        const gaugeDataMetersConcernedSites = JSON.parse(JSON.stringify(this.progressData));
        this.sitesWithMeters.forEach(sm => {
            sm.meters.forEach(m => {
                const has_releve = this.metersDataMap.has(m.uid_site + m.uid);
                if (has_releve) {
                    this.totalMetersDoneCount++;
                    this.concerned_sites.push(m.uid_site);
                }
            });
        });
        this.concerned_sites = H.arrayUnique(this.concerned_sites);
        gaugeDataMetersConcernedSites[0].value = this.concerned_sites.length;
        this.renderPlot('gaugeProgress-meters-concerned-sites', gaugeDataMetersConcernedSites, this.progressGaugeLayout);

        gaugeDataMeters[0].value = this.totalMetersDoneCount;
        gaugeDataMeters[0].gauge.axis.range = [null, this.totalMetersCount];
        gaugeDataMeters[0].gauge.axis.tickvals = this.getTicksFromMax(this.totalMetersCount);
        this.progressGaugeLayout['title']['text'] = '';
        this.progressGaugeLayout['datarevision']++;
        this.renderPlot('gaugeProgress-meters', gaugeDataMeters, this.progressGaugeLayout);

        const gaugeDataShared = JSON.parse(JSON.stringify(this.progressData));
        let sharedCount = 0;
        this.events.forEach(ev => {
            if (ev.related_data['report_key'] === this.selectedKey)
                sharedCount++;
        });
        gaugeDataShared[0].value = sharedCount;
        gaugeDataShared[0].gauge.axis.range = [null, this.sitesWithMeters.length];
        gaugeDataShared[0].gauge.axis.tickvals = this.getTicksFromMax(this.sitesWithMeters.length);
        gaugeDataShared[0].gauge.steps = [];
        this.progressGaugeLayout['title']['text'] = '';
        this.renderPlot('gaugeProgress-shared', gaugeDataShared, this.progressGaugeLayout);
    }

    renderPie() {
        this.perUser = new Map<string, number>();
        this.events.forEach(ev => {
            if (ev.related_data['report_key'] === this.selectedKey) {
                const m = moment.unix(ev.ts);
                // const keyforTime = m.format('d-HH');
                const keyforTimeLabel = m.format('ddd-HH');
                const key = 'SHARED:' + ev.user_metas.email;
                // this.perUserLabel.set(keyforTime, keyforTimeLabel);
                H.incrStatMap(this.perUser, key);
                // H.incrStatMap(this.perHour, keyforTime);
            }
        });
        console.log('renderPie', this.perUser);
        const pieUsers = JSON.parse(JSON.stringify(this.pieData));
        this.perUser.forEach((v, k) => {
            const chunks = k.split(':');
            const type = chunks[0];
            const user = H.getFirstNameFromEmail(chunks[1]);
            // this.colorsMap[user] = '#9823af';
            const userLabel = user + ' (' + v + ')';
            this.userLabelToEmailMap.set(userLabel, chunks[1]);
            pieUsers[0].values.push(v);
            pieUsers[0].labels.push(userLabel);
            pieUsers[0].marker.colors.push(this.colorsMap[user]);
        });

        this.pieLayout.title.text = "";

        Plotly.newPlot('pie-users', pieUsers, this.pieLayout).then(gd => {
            gd.on('plotly_legendclick', (e) => {
                // console.log("User selected---------------------", e.label, this.userLabelToEmailMap.get(e.label));
                this.checkUserActivity(this.userLabelToEmailMap.get(e.label));
                return false;
            });
        });
    }

    checkUserActivity(email: string) {
        this.highlighedSites = new Map<string, string>();
        this.highlighedSitesNames = new Map<string, string>();
        return this.myapp.db.collection<any>('users', ref =>
            ref.where('email', '==', email)
        ).valueChanges()
            .subscribe(users => {
                if (users.length > 0) {
                    const userObj = new User(users[0]);
                    if (userObj.site_access) {
                        userObj.site_access.forEach((v, k) => {
                            //console.log("userObj", userObj.email, v.site_name, v.rights.includes('BMENS@SHARE'));
                            if ((v.rights.includes('BMENS@SHARE'))) {
                                const is_shared = this.events.find(it =>
                                    it.related_data['report_key'] === this.selectedKey
                                    && it.related_data['site_id'] === v.uid_site
                                    && it.user_metas.email === userObj.email);
                                console.log("is_chared", is_shared);
                                if (is_shared !== undefined) {
                                    this.highlighedSites.set(k, is_shared.date_str);
                                    this.highlighedSitesNames.set(v.site_name, is_shared.date_str);
                                } else {
                                    this.highlighedSites.set(k, 'PENDING');
                                    this.highlighedSitesNames.set(v.site_name, 'PENDING');
                                }
                            }
                        });
                    }
                    // console.log("userObj", userObj, userObj.site_access, this.highlighedSites);
                }
            });
    }

    selectReleve(sm: SiteWithMeters, m: BmensMeter) {
        this.selectedSiteWithMeters = sm;
        this.selectedMeter = m;
        this.selectedReleveItem = this.metersDataMap.get(m.uid_site + m.uid);
        this.selectedShareItem = this.eventsMap.get(sm.siteUUID + this.selectedKey);
        const show = {
            siteWithMeter: sm,
            meter: this.selectedMeter, releve: this.selectedReleveItem, share: this.selectedShareItem
        };
        console.log("selectReleve", show);
    }

    plotClic(ev) {
        this.highlighedSites = new Map<string, string>();
        this.highlighedSitesNames = new Map<string, string>();
        // console.log("plotClic", ev);
        if (ev['points'] && ev['points'][0] && ev['points'][0].x) {
            this.selectedMonth = ev['points'][0].x;
            this.selectedKey = this.selectedYear + (this.selectedMonth < 10 ? 0 + this.selectedMonth.toString() : this.selectedMonth.toString());
            this.renderBars();
            this.loadReleves();
        }

    }

    loadReleves() {
        this.metersData = [];
        this.metersDataMap = new Map<string, BmensData>();
        this.site.api.getBmensData(this.selectedYear, this.selectedMonth).subscribe(resp => {
            console.log("plotClic", resp);
            resp.releves.forEach(rel => {
                const r = new BmensData(rel);
                r._meterObj = this.metersMap.get(r.uid_meter);
                const keyToMap = r.uid_site + r.uid_meter;
                this.metersDataMap.set(keyToMap, r);
                this.metersData.push(r);
            });
            this.metersData = this.metersData.sort((a, b) => {
                return a.ts_insert - b.ts_insert
            });
            this.renderGauges();
            this.renderPie();
        });
    }

    ngOnDestroy() {
    }

    renderPlot(divID: string, data, layout) {
        if (this.renderedDivIds[divID]) {
            console.log("Animating", divID)
            Plotly.animate(divID, {
                data: data,
                traces: [0],
                layout: layout
            }, {
                transition: {
                    duration: 500,
                    easing: 'cubic-in-out'
                },
                frame: {
                    duration: 500
                }
            });
        } else {
            this.renderedDivIds[divID] = true;
            Plotly.newPlot(divID, data, layout);
        }
    }

    getTicksFromMax(max: number) {
        const maxTicks = 5;
        let retval = [];
        retval.push(0);
        retval.push(max);
        const stepLen = Math.floor(max / maxTicks);
        let mult = 1;
        while (retval.length < maxTicks + 1) {
            retval.push(stepLen * mult);
            mult++;
        }
        retval = retval.sort();
        // console.log("getTicksFromMax", retval);
        return retval;
    }
}

class SiteWithMeters {
    siteRef: string;
    siteName: string;
    siteUUID: string;
    meters: BmensMeter[] = [];

    constructor(ref: string, metersArr: any[]) {
        this.siteRef = ref;
        this.meters = metersArr.map(met => new BmensMeter(met)).sort((a, b) => {
            this.siteUUID = a.uid_site;
            return a.disp_order - b.disp_order;
        });

    }
}

// interval(5000).subscribe(() => {
//     for (let s = 0; s < 5; s++) {
//         this.graph.data[s].x = [];
//         this.graph.data[s].y = [];
//         this.graph.data[s].values = [];
//         this.graph.data[s].labels = [];
//     }
//     for (let i = 1; i <= 12; i++) {
//         for (let j = 0; j < 4; j++) {
//             this.graph.data[j].x.push(i);
//             this.graph.data[j].y.push(H.randomInt(0, 20));
//         }
//     }
//
//     this.graph.layout.datarevision++;
//     // if (this.plotly1) this.plotly1.data = this.graph.data;
//     // console.log("interval", this.graph.data, this.plotly1);
// });