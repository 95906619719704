import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";

import {SiteService} from "../../shared/services/site.service";
import {
    Plot
} from "../../shared/models/models";
import {BaseComponent} from "../../shared/BaseComponent";
import {AppService} from "../../shared/services/app.service";
import {DomSanitizer} from "@angular/platform-browser";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ModelManager} from "../../shared/helpers/ModelManager";
import {H} from "../../shared/helpers/H";
import {K} from "../../shared/models/K";
import * as Highcharts from 'highcharts';
import {Optimise} from "../../shared/helpers/Optimise";

@Component({
    selector: 'statmens-previsions',
    template: `
        <div fxLayout="row">
            <div fxFlex>
                <mat-card class="m-4 p-8">
                    <h3>Consommation correlée de {{ site.year }}</h3>
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="ref-year-choser">
                        <div>Selon modèle de l'année de réf {{ site.refYear }}</div>
                        <div>
                            <ng-container *ngFor="let refY of site.availableYearsInRows">
                                <button *ngIf="refY<site.year" mat-raised-button color="primary" class="btn-xs mr-4"
                                        [class.active]="site.selectedConfigForPdmAndYear.year_ref==refY"
                                        (click)="updateRefYear(refY)">{{ refY }}
                                </button>
                            </ng-container>
                        </div>
                    </div>
                    <table class="bloc-statmens-table " *ngIf="modelManager&&modelManager.currYearCalcedRows.length">
                        <tr class="row-head" *ngFor="let row of modelManager.currYearCalcedRows">
                            <ng-container *ngIf="row.releve_num===2">
                                <td>Relevé : Date</td>
                                <td>No jour</td>
                                <td *ngFor="let v of row.var_meteo|keyvalue" [class.chosen]="chosenVars.includes(v.key)">
                                    {{ v.key }}
                                    <span *ngIf="v.key==='djEP'">{{ modelManager.used_cassure|number }}</span>
                                </td>
                                <td *ngFor="let v of row.var_extra|keyvalue" [class.chosen]="chosenVars.includes(v.key)">
                                    {{ row.var_labels[v.key] }}
                                </td>
                                <td *ngFor="let v of row.var_regs|keyvalue" [class.chosen]="chosenVars.includes(v.key)">
                                    {{ row.var_labels[v.key] }}
                                </td>
                                <td></td>
                                <td>VE</td>
                                <td>Ve-corr</td>
                                <td>Diff</td>
                            </ng-container>

                        </tr>
                        <tr *ngFor="let row of modelManager.currYearCalcedRows;let i=index;">
                            <td class="value">{{ row.releve_num }}:{{ row.date }}</td>
                            <td class="value"
                                [attr.type]="'DAYS_CUMUL'">{{ row.days_cumul }}
                            </td>
                            <td class="value" *ngFor="let v of row.var_meteo|keyvalue">
                                {{ v.value|number }}
                            </td>
                            <td class="value" *ngFor="let v of row.var_extra|keyvalue">
                                {{ v.value|number }}
                            </td>
                            <td class="value" *ngFor="let v of row.var_regs|keyvalue">
                                {{ v.value|number }}
                            </td>
                            <td class="arrow-svg-holder">
                                <img width="60px" src="assets/images/long-right-double-arrow.svg" alt="" class="mb-8">
                            </td>
                            <td class="value">
                                {{ row.ve.raw|number }}
                            </td>
                            <ng-container *ngIf="modelManager.derniere">
                                <td class="value">
                                    {{ modelManager.derniere[i]|number }}
                                </td>
                                <td class="value">
                                    {{ (row.ve.raw - modelManager.derniere[i])|number }}
                                </td>
                            </ng-container>
                        </tr>
                    </table>
                </mat-card>
            </div>
            <div fxFlex="750px">

                <mat-card class="m-4 p-8">
                    <h3 fxLayout="row" fxLayoutAlign="space-between center">
                        <span fxFlex>Modèle</span>
                        <button mat-raised-button class="btn-xs" color="primary" (click)="plotDerniere()">Calculer la derniere</button>
                    </h3>

                    <div fxLayout="row" class="model-summary">
                        <div class="p-4" *ngFor="let cv of site.selectedConfigForPdmAndREFYear.vars_used;let i=index">
                            <span *ngIf="site.selectedConfigForPdmAndREFYear.model[i]">
                                {{ site.selectedConfigForPdmAndREFYear.model[i]|number }} x 
                            </span>
                            <b>{{ varLabel(cv) }}</b>
                        </div>
                    </div>
                    <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"></highcharts-chart>
                </mat-card>
            </div>
        </div>
    `,

    styleUrls: ['../../shared/components/shared-styles/shared.scss']
})
export class StatmensPrevisionsComponent implements OnInit, OnChanges {

    Highcharts: typeof Highcharts = Highcharts;
    chartOptions: Highcharts.Options;

    modelManager: ModelManager;
    chosenVars = [];
    consUnit = 'KWh';

    constructor(public myapp: AppService, public site: SiteService, private snackBar: MatSnackBar, public sanitizer: DomSanitizer) {
        this.site.siteSpecificEventTriggerer.subscribe(eventName => {

            if (eventName === SiteService.PDMS_YEARLY_PLOT_DATA_CALCED) {
                this.bootstrap();
            }
            if (eventName === SiteService.PDMS_YEARLY_CONFIG_CHANGED) {
                this.bootstrap();
            }
            if (eventName === SiteService.PDMS_YEARLY_CONFIG_SELECTED) {
                this.bootstrap();
            }
        });
    }

    ngOnInit() {

    }

    bootstrap() {
        this.chosenVars = this.site.selectedConfigForPdmAndYear.vars_used;
        this.modelManager = new ModelManager(
            this.site.selectedPdmYearlyPlotDataMap,
            this.site.selectedConfigForPdmAndYear,
            this.site.selectedConfigForPdmAndREFYear,
        );

        // console.log("StatmensPrevisions: ",
        //     this.site.year, this.site.refYear,
        //     this.modelManager.consVector, this.modelManager.derniere);
        this.plotDerniere();
    }

    updateRefYear(ref_year: number) {
        // to calculate predictions, we need the chosenVars of the refYear
        // and extract VarExpl of current year according to the chosen vars of the refYear
        // mMul with calced model of refYear
        this.site.updateRefYear(ref_year);
    }

    varLabel(key) {
        if (this.modelManager
            && this.modelManager.currYearCalcedRows
            && this.modelManager.currYearCalcedRows.length
            && this.modelManager.currYearCalcedRows[0].var_labels[key])
            return this.modelManager.currYearCalcedRows[0].var_labels[key];
        else return key;
    }

    plotDerniere() {
        const categories = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc', ''];
        this.modelManager.calcDerniere();
        if (!this.modelManager.derniere) {
            console.error("Plot derniere, derniere undefined");
            return;
        }
        const moisIndexes = [];
        let maxCons = 0;
        let accRealCons = 0;
        let accDerniere = 0;
        const derniereData = this.modelManager.derniere.map((pty, i) => {
            accDerniere += pty;
            moisIndexes.push(i);
            return {x: this.modelManager.daysCumul[i], y: accDerniere / 1000, name: i};
        });
        const realConsData = this.modelManager.consVector.map((pty, i) => {
            const delta = (pty - derniereData[i]['y']) / 1000;
            const deltaPercent = 100 * (delta / derniereData[i]['y']);
            accRealCons += pty;
            if (accRealCons > maxCons) maxCons = accRealCons;
            return {x: this.modelManager.daysCumul[i], y: accRealCons / 1000, name: delta, delta: deltaPercent};
        });

        derniereData.unshift({x: 0, y: 0, name: 0});
        realConsData.unshift({x: 0, y: 0, name: 0, delta: 0});
        moisIndexes.push('');

        this.chartOptions = {
            chart: {
                displayErrors: true,
                plotBackgroundColor: '#ffffff',
                plotShadow: false,
                borderColor: '#000000',
                borderWidth: 0,
                plotBorderWidth: K.lineWidthBase,
                reflow: false,
                width: 750,
                height: 500,
                marginTop: 40,
                marginRight: 50,
                style: {
                    fontFamily: "Roboto",
                    fontSize: "14px"
                }
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            title: {
                text: undefined
            },
            lang: {
                thousandsSep: "'",
                numericSymbols: null,
            },
            xAxis: [
                {
                    lineWidth: 2,
                    lineColor: '#000000',
                    gridLineColor: "#000000",
                    gridLineWidth: 0.2,
                    tickInterval: 30,
                    crosshair: true,
                    min: 0,
                    max: 390,
                    startOnTick: true,
                    endOnTick: false,
                    labels: {
                        style: {
                            color: '#000000',
                            fontSize: '14px',
                        }
                    },
                    title: {
                        text: "Jours cumulés"
                    }
                },
                {
                    labels: {
                        style: {
                            color: '#000000',
                            fontSize: '11px'
                        }
                    },
                    type: "category",
                    opposite: true,
                    lineWidth: 0,
                    offset: -45,
                    categories: categories,
                    crosshair: true
                }
            ],
            yAxis: {
                lineWidth: 2,
                lineColor: '#000000',
            },
            accessibility: {
                enabled: false
            },
            series: [
                {
                    dataLabels: {
                        useHTML: true,
                        enabled: true,
                        inside: true,
                        x: 5,
                        y: 5,
                        align: "left",
                        position: "right",
                        rotation: 30,
                        borderWidth: 1,
                        borderColor: 'red',
                        padding: 20,
                        allowOverlap: true,
                        style: {fontWeight: "regular"},
                        format: "{point.name:,.0f}  " + this.consUnit + "; {point.delta: .1f}% ",
                    },
                    marker: {
                        fillColor: '#ffffff',
                        lineColor: '#ff0000',
                        width: 50,
                        enabled: true,
                        lineWidth: 2,
                        symbol: 'square',
                        radius: 3
                    },
                    color: K.chartColors[0],
                    lineWidth: 2,
                    name: "Valeurs réelles",
                    type: "line", tooltip: {
                        headerFormat: "{series.name}",
                        pointFormat: '<br>{point.category}: <b> {point.y:.2f}</b>',
                    },
                    data: realConsData || []
                },
                {
                    marker: {enabled: false},
                    xAxis: 0,
                    color: 'black',
                    lineWidth: 2,
                    name: "Dernière",
                    type: "line", tooltip: {
                        headerFormat: "{series.name}",
                        pointFormat: '<br>{point.category}: <b> {point.y:.2f}</b>',
                    },
                    data: derniereData || []
                },
                {
                    // virtual serie to make second axis shoing month names show ! Hack
                    showInLegend: false,
                    xAxis: 1,
                    color: 'transparent',
                    name: "Mois",
                    type: "line",
                    data: moisIndexes || []
                },
            ]
        };
    }

    ngOnChanges(changes
                    :
                    SimpleChanges
    ) {
    }

}
