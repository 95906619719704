import {PdmYearlyCalcedData, PdmYearlyConfig} from "../models/PdmYearConfig";

import * as MlMatrix from "ml-matrix";
import * as MathJS from "mathjs";

export class ModelManager {
    // PdmYearlyCalcedData is generated on the fly according to RowsData and Chosen VarExp, not stored,
    yearlyCalcedDataMap: Map<number, PdmYearlyCalcedData> = new Map<number, PdmYearlyCalcedData>();
    currYearCalcedRows: PdmYearlyCalcedData[] = [];
    consVector: any[] = [];
    predictedVector: any[] = [];
    varExplMatrix: any[] = [];
    chosenVars: string[];
    modelMat: any;
    derniereMat: any;
    // --- Exported data
    derniere: number[];
    daysCumul: number[];// x data derniere
    r2: number = 0;
    used_cassure: number = 0;
    model: any;
    error: any;

    constructor(yearlyCalcedDataMap: Map<number, PdmYearlyCalcedData>, currYearConfig: PdmYearlyConfig, refYearConfig: PdmYearlyConfig = null) {
        this.yearlyCalcedDataMap = yearlyCalcedDataMap;
        this.currYearCalcedRows = Array.from(this.yearlyCalcedDataMap.values())
            .filter(item => item.releve_year === currYearConfig.year)
            .sort((a, b) => a.releve_num - b.releve_num);
        this.extractConsVector();

        // populate days cumul
        this.daysCumul = [];
        this.currYearCalcedRows.forEach(row => {
            this.daysCumul.push(row.days_cumul);
        });

        if (refYearConfig) {
            // derniere calc mode
            this.chosenVars = refYearConfig.vars_used;
            this.model = refYearConfig.model; // model is already calced and saved in refYear config
            this.extractVarExplMatrix();
            this.calcDerniere();
        } else {
            // model calc mode
            this.chosenVars = currYearConfig.vars_used;
            this.extractVarExplMatrix();
            if (this.chosenVars.length > 1)
                this.calcModel(); // model is calculated
        }
    }

    // the "b" side in Ax=b
    extractConsVector() {
        this.consVector = this.currYearCalcedRows.map(item => item.ve.raw);
    }

    extractVarExplMatrix() {
        // extract varExpl matrix according to chosenVars and the selectedRows
        const varExplMatrix = [];
        this.currYearCalcedRows.forEach(row => {
            const oneVarVect = [];
            Object.keys(row.var_meteo).forEach(k => {
                if (k === 'used_cassure') this.used_cassure = row.var_meteo[k];
                if (this.chosenVars.includes(k))
                    oneVarVect.push(row.var_meteo[k]);
            });
            Object.keys(row.var_extra).forEach(k => {
                if (this.chosenVars.includes(k))
                    oneVarVect.push(row.var_extra[k]);
            });
            Object.keys(row.var_regs).forEach(k => {
                if (this.chosenVars.includes(k))
                    oneVarVect.push(row.var_regs[k]);
            });
            /// TODO: NEW FIELD
            if (this.chosenVars.includes('DAYS_CUMUL'))
                oneVarVect.push(row.days_cumul);
            varExplMatrix.push(oneVarVect);
        });
        this.varExplMatrix = varExplMatrix;
    }

    calcModel() {
        if (this.chosenVars.length < 2) {
            console.error("ModelManager ChosenVars.len < 2");
            return;
        }
        // console.log('calcModel Start', this.consVector, this.varExplMatrix);
        const aMat = new MlMatrix.Matrix(this.varExplMatrix);
        const bMat = MlMatrix.Matrix.columnVector(this.consVector);
        this.modelMat = MlMatrix.solve(aMat, bMat, true);
        this.error = MlMatrix.Matrix.sub(aMat.mmul(this.modelMat), bMat).to1DArray();
        this.model = this.modelMat.to1DArray();
        this.predictedVector = aMat.mmul(this.modelMat).to1DArray();

        this.rSquared();
        // console.log('calcModel', this.model, this.error, aMat);
        // console.log(' this.predictedVector', this.predictedVector);
        // const linearDepOfVars = MlMatrix.linearDependencies(aMat.transpose());
    }

    calcDerniere() {
        if (this.model.length < 2) {
            console.error("No model for year");
            return;
        }
        const aMat = new MlMatrix.Matrix(this.varExplMatrix);
        const relConsMat = MlMatrix.Matrix.columnVector(this.consVector);
        this.modelMat = MlMatrix.Matrix.columnVector(this.model);
        console.log("calc derniere", this.modelMat, aMat);
        this.derniereMat = aMat.mmul(this.modelMat);
        this.derniere = this.derniereMat.to1DArray();
        this.error = MlMatrix.Matrix.sub(relConsMat, this.derniereMat).to1DArray();
        this.rSquared();
        // console.log("calcDerniere", this.model, this.varExplMatrix, this.derniere);
    }

    rSquared() {
        let regressionSquaredError = 0, totalSquaredError = 0;
        const yMean = MathJS.mean(this.consVector);
        this.consVector.forEach((y, i) => {
            regressionSquaredError += Math.pow(this.error[i], 2);
            totalSquaredError += Math.pow(y - yMean, 2);
        });
        // console.log("r2", 1 - (regressionSquaredError / totalSquaredError), this.consVector);
        this.r2 = 1 - (regressionSquaredError / totalSquaredError);
    }
}
