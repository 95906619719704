import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {AppConfirmService} from "../../../shared/services/app-confirm/app-confirm.service";
import {UserSession} from "../../../shared/models/UserSession.model";
import {Subscription} from "rxjs";
import {ProtoReportPage} from "../../../shared/models/ProtoHebdo";

@Component({
    selector: 'app-current-sessions',
    template: `
        <div fxLayout="row">
            <!-- left listfxLayoutAlign="space-between start" -->
            <mat-card fxFlex="300px" class="default full-width">
                <mat-card-title class="p-8 list-user-head">
                    Utilisateurs
                </mat-card-title>
                <mat-card-content fxLayout="column" fxLayoutAlign="start stretch" class="flex-container p-0">
                    <div class="filter">
                        <input placeholder="Filtrer" [(ngModel)]="filterUser">
                    </div>
                    <div class="scroller">
                        <mat-list class="full-widt h mb-32 compact-list" fxLayout="column" fxLayoutAlign="start none">
                            <ng-container *ngFor="let user of usersToDisplay">
                                <mat-list-item class="full-wid th p-8" fxLayout="row" fxLayoutAlign="space-between center"
                                               (click)="selectUser(user)"
                                               *ngIf="!filterUser||(user.name.toLowerCase().includes(filterUser.toLowerCase()))"
                                               [ngClass]="{active:selectedUser&&selectedUser.uid===user.uid}">

                                    <h6 class="m-0 ml-8" fxFlex><span>{{ user.name }} </span></h6>
                                    <span class="text-muted"> {{ user.lastOnline|amTimeAgo }}</span>

                                </mat-list-item>
                            </ng-container>
                        </mat-list>
                    </div>

                </mat-card-content>
            </mat-card>
            <div fxFlex>
                <div fxFlex="450px" *ngIf="selectedUser" class="m-4 p-4 mt-8">
                    <ng-container *ngFor="let siteReflastHistory of selectedUser.visitedRefs">
                        <mat-card class="m-4 p-8" *ngIf="siteReflastHistory.siteREF">
                            <div fxLayout="row" (click)="selectedSiteName=siteReflastHistory.siteREF">
                                <span fxFlex>{{ siteReflastHistory.siteREF }}</span>
                                <div>
                                    <span> Version de: {{ siteReflastHistory.appVersion|amTimeAgo }}  </span><br>
                                    <span> Visité: {{ siteReflastHistory.lastOnline|amTimeAgo }}  </span>
                                </div>
                            </div>
                        </mat-card>
                    </ng-container>
                </div>
                
                <div fxFlex *ngIf="selectedSiteName&&selectedUser.history[selectedSiteName]"
                     class="m-4 p-4 mt-8">
                    <mat-card class="m-4  p-4 "
                              *ngFor="let history of selectedUser.getHistory(selectedSiteName)">
                        <div class="history-item">
                            <label>Onglet</label>
                            <span>{{ history.wat }}</span>
                        </div>
                        <div class="history-item">
                            <label>Batiment ID</label>
                            <span>{{ history.siteUID }}</span>
                        </div>
                        <div class="history-item" *ngIf="history.appVersion">
                            <label>Deploiement du</label>
                            <span>{{ history.appVersion|amTimeAgo }}</span>
                        </div>
                        <div class="history-item">
                            <label>Visité</label>
                            <span>{{ history.lastOnline|amTimeAgo }}</span>
                        </div>
                        <div class="history-item">
                            <label>Date</label>
                            <span>{{ history.lastOnline|amLocal }}</span>
                        </div>
                    </mat-card>
                </div>
            </div>

        </div>
    `,
    styleUrls: ['./manager.component.scss']
})
export class CurrentSessionsComponent implements OnInit, OnDestroy {
    usersMap: Map<string, UserSession> = new Map<string, UserSession>();
    usersToDisplay: UserSession[] = [];
    selectedUser: UserSession;
    selectedSiteName: string;
    rdbSubscription: Subscription;
    filterUser = '';

    constructor(
        public myapp: AppService,
        public site: SiteService,
    ) {

    }

    ngOnInit(): void {
        if (this.myapp.user) {
            this.run();
        } else {
            this.myapp.appInitStatus.subscribe(step => {
                if (step === 1)
                    this.run();
            });
        }
    }

    run() {
        this.myapp.storeCurrentRoute();
        this.rdbSubscription = this.myapp.rdb.list('users').valueChanges()
            .subscribe(nodes => {
                nodes.forEach(u => {
                    if (u['name']) {
                        const userSess = new UserSession(u);
                        this.usersMap.set(userSess.name, userSess);

                        if (this.selectedUser && this.selectedUser.name === userSess.name) {
                            this.selectedUser = userSess;
                        }
                    }
                });
                this.usersToDisplay = Array.from(this.usersMap.values());
                this.usersToDisplay = this.usersToDisplay.sort((a, b) => {
                    return b.lastOnline - a.lastOnline;
                });

            });
    }

    selectUser(u) {
        this.selectedUser = u;
        console.log("selectUser", u);
    }

    ngOnDestroy() {
        if (this.rdbSubscription) this.rdbSubscription.unsubscribe();
    }

}
